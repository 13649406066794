import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useCallback } from "react";

import { getApplications, getInvitationDetails } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { InvitationDetails, LoanType } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import useSmoothAnchors from "@prime/pop-components/src/hooks/useSmoothAnchors";
import { Footer, Header } from "@prime/pop-components/src/layout";
import { Guide, PromoTerms } from "@prime/pop-components/src/promotional";
import { Button } from "@prime/ui/src/button";
import { AxiosError } from "axios";

export const Route = createFileRoute("/invitations/$invitationId/")({
  beforeLoad: async (props) => {
    const { context, params, cause } = props;
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;
    const { invitationId } = params;

    if (isAuthenticated && cause === "enter") {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });
        if (applications?.length) {
          const { to: redirectTo, params: redirectParams } =
            getRouteByApplicationStage(applications?.[0]);

          throw redirect({
            to: redirectTo,
            params: redirectParams,
            replace: true,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }

    try {
      const details = await getInvitationDetails({
        apiClient,
        params: {
          invitationId,
        },
      });

      console.log(details);

      return {
        details,
      };
    } catch (error) {
      const hostname = window.location.hostname;
      if (hostname === "localhost") {
        window.location.href = `http://localhost:3000/`;
        return {
          details: null,
        };
      }
      window.location.href = `https://${hostname.replace("apply.", "www.")}/`;
    }

    return {
      details: null,
    };
  },
  component: Page,
  pendingComponent: LoaderScreen,
});

function Hero() {
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  const context = Route.useRouteContext();
  const {
    term_length = 91,
    loan_amount,
    loan_total_amount,
    apr,
    business_name,
    expiration,
    weekly_payment,
    loan_type,
    origination_fee_amount,
    claimed = false,
  } = ((context as Record<string, unknown>).details || {}) as InvitationDetails;

  const getApproved = useCallback(() => {
    navigate({
      to: "/invitations/$invitationId/claim",
      params,
    });
  }, [params, navigate]);

  const signIn = useCallback(() => {
    window.location.href = "/";
    // For some strange reason navigate renders the index route the first time without context
    // navigate({
    //   to: "/",
    // });
  }, []);

  return (
    <div className="m-12 flex min-h-[460px] w-full max-w-[380px] flex-col items-center justify-start overflow-hidden rounded-xl text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)] transition-transform duration-500 md:m-[120px] md:min-h-[412px] md:w-full md:max-w-[840px] md:flex-row">
      <div className="box-border flex flex-1 flex-col items-start justify-start gap-[45px] self-stretch rounded-t-xl bg-[hsl(var(--gray-100)/0.3)] bg-white bg-opacity-50 px-6 pb-3 pt-4 [backdrop-filter:blur(32px)] sm:py-6 md:min-w-[360px] md:rounded-l-xl md:rounded-r-[0]">
        <div className="flex flex-1 flex-col items-start justify-start gap-4 self-stretch text-left md:gap-6">
          <div className="relative flex flex-1 flex-col items-start justify-between self-stretch">
            <p className="text-btn text-[14px] font-medium uppercase md:text-xl">
              {business_name}
            </p>
            <p className="text-txt-primary my-3 hidden text-[40px] leading-[125%] md:block md:text-[42px]">
              You’re pre-qualified for{" "}
              <span className="rounded-md px-3 [background:linear-gradient(rgba(255,_255,_255,_0.75),_rgba(255,_255,_255,_0.75)),_linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]">
                <span className="!bg-clip-text text-transparent [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]">
                  ${Number(loan_amount).toLocaleString("en-US")}
                </span>
              </span>{" "}
              of fast funding.
            </p>

            <div className="text-txt-primary relative z-[3] mb-6 hidden self-stretch text-base leading-[150%] md:block">
              Congratulations, Pop is tracking 3 million small businesses and
              yo&apos;re in our <strong>top 5% of financial performers.</strong>{" "}
              So we’re extended this exclusive financing offer to help your
              business keep growing!
            </div>
            <div className="text-txt-dimmed mt-[3px] flex w-full flex-row items-center justify-start self-start overflow-hidden text-center text-xs font-medium uppercase md:mt-auto md:w-[unset] md:justify-start md:justify-center">
              {!claimed ? (
                <>
                  OFFER EXPIRES{" "}
                  {new Date(expiration)
                    .toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                    .toUpperCase()}
                </>
              ) : (
                <>OFFER CLAIMED</>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="bg-surface-card box-border flex flex-1 flex-col items-start justify-center self-stretch overflow-hidden border-l-[1px] border-solid bg-white text-left text-sm md:min-w-[312px] md:rounded-r-xl">
        <div className="text-txt-secondary flex flex-1 flex-col items-start justify-between gap-6 self-stretch overflow-hidden rounded-b-xl rounded-t-none p-6 text-sm">
          <div className="flex flex-col items-start justify-start self-stretch overflow-hidden rounded-b-none rounded-t-xl">
            <div className="text-icon flex flex-row items-center justify-start gap-[8px] self-stretch font-medium uppercase">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Check Badge Icon"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.9042 13.9811C20.1508 13.3202 21 12.0093 21 10.5C21 8.99072 20.1508 7.67977 18.9042 7.01887C19.3184 5.67003 18.9919 4.14258 17.9246 3.07535C16.8574 2.00812 15.33 1.6816 13.9811 2.09579C13.3202 0.849162 12.0093 0 10.5 0C8.99071 0 7.67976 0.849178 7.01886 2.09582C5.67003 1.68165 4.1426 2.00817 3.07538 3.07539C2.00816 4.14261 1.68164 5.67004 2.0958 7.01887C0.849171 7.67977 0 8.99072 0 10.5C0 12.0093 0.849172 13.3202 2.09581 13.9811C1.68162 15.33 2.00814 16.8574 3.07537 17.9246C4.1426 18.9919 5.67004 19.3184 7.01888 18.9042C7.67978 20.1508 8.99072 21 10.5 21C12.0093 21 13.3202 20.1508 13.9811 18.9042C15.33 19.3184 16.8574 18.9918 17.9246 17.9246C18.9918 16.8574 19.3184 15.33 18.9042 13.9811ZM15.5617 8.12586C15.8815 7.68618 15.7843 7.07054 15.3446 6.75078C14.9049 6.43101 14.2893 6.52822 13.9695 6.96789L9.3971 13.255L6.93043 10.7883C6.54601 10.4039 5.92274 10.4039 5.53832 10.7883C5.15389 11.1727 5.15389 11.796 5.53832 12.1804L8.81957 15.4617C9.02313 15.6653 9.30562 15.7695 9.59262 15.747C9.87963 15.7245 10.1424 15.5774 10.3117 15.3446L15.5617 8.12586Z"
                  fill="#595FEF"
                />
              </svg>

              <p className="uppercase">Prequalified Loan Offer</p>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
              <div className="flex h-10 flex-row items-center justify-start gap-[3px]">
                <div className="relative opacity-[0.5]">$</div>
                <div className="text-txt-primary relative text-[36px]">
                  {Number(loan_amount).toLocaleString("en-US")}
                </div>
              </div>
              <div className="relative text-left">
                {Math.round(term_length / 30.4)} Months
              </div>
            </div>
            {loan_type === LoanType.OriginationFeeNoInterest ? (
              <div className="flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
                <div className="relative leading-[150%]">
                  Fixed Fee<sup className="text-[10px]"> {3}</sup>
                </div>
                <div className="relative text-left">
                  ${Number(origination_fee_amount).toLocaleString("en-US")}
                </div>
              </div>
            ) : null}
            <div className="flex flex-row items-center justify-between self-stretch border-b-[1px] border-solid px-1.5 py-4">
              <div className="relative leading-[150%]">Weekly Payment</div>
              <div className="relative text-left">
                ${Number(weekly_payment).toLocaleString("en-US")}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between self-stretch px-1.5 py-4">
              <div className="relative leading-[150%]">Total Cost</div>
              <div className="relative text-left">
                ${Number(loan_total_amount).toLocaleString("en-US")}
              </div>
            </div>
            {loan_type !== LoanType.OriginationFeeNoInterest ? (
              <div className="flex flex-row items-center justify-between self-stretch border-t-[1px] border-solid px-1.5 py-4">
                <div className="relative leading-[150%]">Effective APR</div>
                <div className="relative text-left">{apr}%</div>
              </div>
            ) : null}
          </div>
          {!claimed ? (
            <Button
              className="mt-5 h-12 w-full rounded-xl text-base md:mt-6"
              variant={"gradient"}
              onClick={getApproved}
            >
              <div className="relative font-medium leading-[24px]">
                Apply Now
              </div>
            </Button>
          ) : (
            <Button
              className="mt-5 h-12 w-full rounded-xl text-base md:mt-6"
              variant={"gradient"}
              onClick={signIn}
            >
              <div className="relative font-medium leading-[24px]">Sign In</div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function Page() {
  const context = Route.useRouteContext();
  const { loan_type } = ((context as Record<string, unknown>).details ||
    {}) as InvitationDetails;
  useSmoothAnchors();
  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="flex w-full max-w-[1440px] flex-1 flex-col">
        <Header className="border-0">
          <div className="hidden flex-row items-center justify-start gap-6 p-3 text-center text-base md:flex">
            <div className="flex flex-row items-center justify-center overflow-hidden rounded-3xl px-[18px] py-1.5">
              <a
                href="#guide"
                className="relative leading-[150%] text-[hsl(var(--brand))]"
              >
                Borrower Guide
              </a>
            </div>
          </div>
        </Header>
        <div className="m-0 flex flex-1 flex-col items-center justify-center overflow-hidden bg-[url(/assets/campaign-bg.png)] bg-cover bg-no-repeat shadow-lg md:m-3 md:mb-0 md:rounded-xl">
          <Hero />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center p-0 md:p-3 md:pt-12">
          <div className="w-full p-0 md:pb-12">
            <PromoTerms
              originationProduct={
                loan_type === LoanType.OriginationFeeNoInterest
              }
            />
          </div>
          <Guide />
        </div>
        <Footer />
      </div>
    </div>
  );
}
